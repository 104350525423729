import { useFloating, useDismiss, useInteractions, autoUpdate } from '@floating-ui/react'
import { animated, useTransition } from '@react-spring/web'

import { useTranslate } from '/machinery/I18n'
import { useSignalValue } from '/machinery/signals'

import { useMenuItems, useMenuItemsCanonical, useMenuItemsOverview, useMenuItemsSelector } from '/features/pageOnly/menu/useMenuItems'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Menu.css'

import checkIcon from '/images/icons/check.raw.svg'

export function MenuDeckFeed({ tocDrawer, searchDrawer, currentArticleIndex$, articleCount, finishedReading$, layoutClassName }) {
  const currentArticleIndex = useSignalValue(currentArticleIndex$)
  const finishedReading = useSignalValue(finishedReading$)

  const menuItems = useMenuItems({ tocDrawer, searchDrawer })

  return (
    <MenuBase
      {...{ menuItems, finishedReading, layoutClassName }}
      renderMenuContent={() => (
        <MenuContent
          total={articleCount}
          currentIndex={currentArticleIndex}
          showMenuText={currentArticleIndex === 0}
          {...{ finishedReading }}
        />
      )}
    />
  )
}

export function Menu({ tocDrawer, searchDrawer, currentArticleIndex, articleCount, finishedReading, layoutClassName }) {
  const menuItems = useMenuItems({ tocDrawer, searchDrawer })

  return (
    <MenuBase
      {...{ menuItems, finishedReading, layoutClassName }}
      renderMenuContent={() => (
        <MenuContent
          total={articleCount}
          currentIndex={currentArticleIndex}
          showMenuText={currentArticleIndex === 0}
          {...{ finishedReading }}
        />
      )}
    />
  )
}

export function MenuCanonical({ searchDrawer, layoutClassName }) {
  const menuItems = useMenuItemsCanonical({ searchDrawer })

  return (
    <MenuBase
      renderMenuContent={() => (<MenuContent showMenuText />)}
      {...{ menuItems, layoutClassName }}
    />
  )
}

export function MenuOverview({ layoutClassName }) {
  const menuItems = useMenuItemsOverview()

  return (
    <MenuBase
      renderMenuContent={() => (<MenuContent showMenuText />)}
      {...{ menuItems, layoutClassName }}
    />
  )
}

export function MenuSelector({ searchDrawer, layoutClassName }) {
  const menuItems = useMenuItemsSelector({ searchDrawer })

  return (
    <MenuBase
      renderMenuContent={() => (<MenuContent showMenuText />)}
      {...{ menuItems, layoutClassName }}
    />
  )
}

function MenuBase({
  menuItems,
  renderMenuContent,
  finishedReading = undefined,
  layoutClassName = undefined
}) {
  const [isOpen, setIsOpen] = React.useState(false)
  const { refs, getReferenceProps } = useFloatingProps({ isOpen, onOpenChange: setIsOpen })

  return (
    <nav
      onMouseDown={(e) =>  e.stopPropagation()}
      onPointerDown={(e) =>  e.stopPropagation()}
      onClick={(e) =>  e.stopPropagation()}
      className={cx(styles.componentBase, layoutClassName)}
    >
      <div {...getReferenceProps()} ref={refs.setReference} className={cx(styles.menuContainer, isOpen && styles.isOpen)}>
        <HamburgerButton onOpenChange={setIsOpen} {...{ isOpen, finishedReading, renderMenuContent }} />

        <MenuList items={menuItems} {...{ isOpen }} />
      </div>
    </nav >
  )
}

function HamburgerButton({
  isOpen,
  onOpenChange,
  renderMenuContent,
  finishedReading = undefined,
  layoutClassName = undefined
}) {
  const { __ } = useTranslate()

  return (
    <button
      type='button'
      onClick={handleClick}
      data-x={isOpen ? 'click-to-close-menu' : 'click-to-open-menu'}
      aria-label={__`open-menu`}
      className={cx(
        styles.componentHamburgerButton,
        isOpen && styles.isOpen,
        finishedReading && styles.isFinished,
        layoutClassName
      )}
    >
      <HamburgerIcon {...{ isOpen }} />

      <div className={cx(styles.menuContentContainer, isOpen && styles.isOpen)}>
        {renderMenuContent()}
      </div>
    </button>
  )

  function handleClick() {
    onOpenChange(!isOpen)
  }
}

function HamburgerIcon({ isOpen }) {
  return <span className={cx(styles.componentHamburgerIcon, isOpen && styles.isOpen)} />
}

function MenuContent({ showMenuText, total = undefined, currentIndex = undefined, finishedReading = undefined }) {
  const { __ } = useTranslate()

  const renderCounter = (total !== undefined) && (currentIndex !== undefined)

  return (
    <div className={styles.componentContent}>
      <p className={cx(styles.menuText, showMenuText && styles.isActive)}>{__`menu`}</p>

      {renderCounter && <Counter isActive={!showMenuText} {...{ currentIndex, total }} />}

      {(finishedReading !== undefined) && <CheckIcon isActive={finishedReading} />}
    </div>
  )
}

function Counter({ isActive, currentIndex, total }) {
  const action = useCounterAction({ currentIndex })
  const transitions = useCounterTransition({ currentIndex, action })

  return (
    <div className={cx(styles.componentCounter, isActive && styles.isActive)}>
      <span className={styles.currentContainer}>
        {transitions((style, item) => (
          <animated.span className={styles.counter} {...{ style }}>
            {item}
          </animated.span>
        ))}
      </span>

      <span>/{total}</span>
    </div>
  )
}

function CheckIcon({ isActive }) {
  return (
    <div className={cx(styles.componentCheckIcon, isActive && styles.isActive)}>
      <Icon icon={checkIcon} layoutClassName={styles.iconLayout} />
    </div>
  )
}

function MenuList({ items, isOpen }) {
  return (
    <div className={cx(styles.componentList, isOpen && styles.isOpen)}>
      <ul className={styles.list}>
        {items.map((item, i) => (
          <li
            key={i}
            style={{ '--index': i }}
            className={cx(
              styles.listItem,
              isOpen && styles.isOpen,
              item.isActive && styles.isActive
            )}
          >
            <MenuItem layoutClassName={styles.itemLayout} {...{ item }} />
          </li>
        ))}
      </ul>
    </div>
  )
}

function MenuItem({ item, layoutClassName = undefined }) {
  const { icon } = item

  return (
    <div className={cx(styles.componentItem, layoutClassName)}>
      {icon && <Icon layoutClassName={styles.iconLayout} {...{ icon }} />}
      <MenuItemButton layoutClassName={styles.buttonLayout} {...{ item }} />
    </div>
  )
}

function MenuItemButton({ item,  layoutClassName = undefined }) {
  switch (item.type) {
    case 'link': return <MenuLink {...{ item, layoutClassName }} />
    case 'button': return <MenuButton {...{ item, layoutClassName }} />
    default: return null
  }
}

function MenuLink({ item, layoutClassName }) {
  const { href, label, value } = item

  return (
    <a data-x={`link-in-menu-to-${value}`} className={cx(styles.componentLink, layoutClassName)} {...{ href }}>
      {label}
    </a>
  )
}

function MenuButton({ item,  layoutClassName }) {
  const { onClick, label, value } = item

  return (
    <button
      type='button'
      data-x={`link-in-menu-to-${value}`}
      className={cx(styles.componentButton, layoutClassName)}
      {...{ onClick }}
    >
      {label}
    </button>
  )
}

function useCounterAction({ currentIndex }) {
  const previousCounterRef = React.useRef(null)
  const action = (
    previousCounterRef.current === null ? 'none' :
    previousCounterRef.current < currentIndex ? 'increase' :
    'decrease'
  )

  previousCounterRef.current = currentIndex

  return action
}

/** @param {{ currentIndex: number, action: 'none' | 'increase' | 'decrease' }} props */
function useCounterTransition({ currentIndex, action }) {
  return useTransition(currentIndex, {
    from: {
      opacity: action === 'none' ? 1 : 0,
      y: (
        action === 'increase' ? '10px' :
        action === 'decrease' ? '-10px' :
        '0px'
      ),
    },
    enter: { opacity: 1, y: '0px' },
    leave: {
      opacity: 0,
      y: (
        action === 'increase' ? '-10px' :
        action === 'decrease' ? '10px' :
        '0px'
      ),
    },
    config: { mass: 1, tension: 220, friction: 35 },
  })
}

function useFloatingProps({ isOpen, onOpenChange }) {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange,
    whileElementsMounted: autoUpdate,
  })

  const dismiss = useDismiss(context, {
    referencePress: false,
    outsidePress: true
  })

  const { getReferenceProps } = useInteractions([dismiss])

  return { refs, getReferenceProps }
}
