import { useLocation, useLocationMatch } from '@kaliber/routing'

import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { useParams } from '/machinery/Params'
import { useURLGeneratorContext } from '/machinery/URLGenerator'

import mapIcon from '/images/icons/map-nl.raw.svg'
import listIcon from '/images/icons/list.raw.svg'
import deckIcon from '/images/icons/deck.raw.svg'
import searchIcon from '/images/icons/search.raw.svg'
import articlesOverviewIcon from '/images/icons/articles-overview.raw.svg'

export function useMenuItems({ tocDrawer, searchDrawer }) {
  const { language, issue } = useParams()

  const drawerIsOpen = tocDrawer.isOpen || searchDrawer.isOpen

  const menuItemHome = useMenuItemHome({ href: routeMap.app.issue({ issue, language }) })
  const menuItemContents = useMenuItemContents({ tocDrawer })
  const menuItemDeckFeed = useMenuItemDeckFeed({ drawerIsOpen, onCloseDrawers: handleCloseDrawers })
  const menuItemArticlesOverview = useMenuItemArticlesOverview({ drawerIsOpen })
  const menuItemSearch = useMenuItemSearch({ searchDrawer })

  return [
    issue && menuItemHome,
    menuItemContents,
    menuItemDeckFeed,
    menuItemArticlesOverview,
    menuItemSearch,
  ].filter(Boolean)

  function handleCloseDrawers() {
    if (searchDrawer.isOpen) searchDrawer.handleClose()
    else if (tocDrawer.isOpen) tocDrawer.handleClose()
  }
}

export function useMenuItemsCanonical({ searchDrawer }) {
  const language = useLanguage()

  const menuItemHome = useMenuItemHome({ href: routeMap.app.home({ language }) })
  const menuItemSearch = useMenuItemSearch({ searchDrawer })
  const menuItemArticlesOverview = useMenuItemArticlesOverview({ drawerIsOpen: searchDrawer.isOpen })

  return [
    menuItemHome,
    menuItemArticlesOverview,
    menuItemSearch,
  ]
}

export function useMenuItemsOverview() {
  const language = useLanguage()

  const menuItemHome = useMenuItemHome({ href: routeMap.app.home({ language }) })
  const menuItemArticlesOverview = useMenuItemArticlesOverview({ drawerIsOpen: false })

  return [
    menuItemHome,
    menuItemArticlesOverview,
  ]
}

export function useMenuItemsSelector({ searchDrawer }) {
  const menuItemHome = useMenuItemHome({ onCloseDrawers: handleCloseDrawers, isActive: !searchDrawer.isOpen })
  const menuItemSearch = useMenuItemSearch({ searchDrawer })
  const menuItemArticlesOverview = useMenuItemArticlesOverview({ drawerIsOpen: false })

  return [
    menuItemHome,
    menuItemSearch,
    menuItemArticlesOverview,
  ]

  function handleCloseDrawers() {
    if (searchDrawer.isOpen) searchDrawer.handleClose()
  }
}

function useMenuItemHome({ onCloseDrawers = undefined, href = undefined, isActive = false }) {
  const { __ } = useTranslate()
  const { route } = useLocationMatch()

  // @ts-ignore
  const currentPageIsSelector = [routeMap.app.home, routeMap.app.issue].includes(route)

  return {
    label: __`map`,
    icon: mapIcon,
    value: 'home',
    type: currentPageIsSelector ? 'button' : 'link',
    isActive,
    onClick: onCloseDrawers,
    href,
  }
}

function useMenuItemContents({ tocDrawer }) {
  const { __ } = useTranslate()

  return {
    label: __`contents`,
    icon: listIcon,
    value: 'inhoudsopgave',
    type: 'button',
    onClick: tocDrawer.handleOpen,
    isActive: tocDrawer.isOpen
  }
}

function useMenuItemDeckFeed({ drawerIsOpen, onCloseDrawers }) {
  const { __ } = useTranslate()
  const params = useParams()
  const { pathname } = useLocation()

  const { determineDeckFeedLink, determineCurrentSlug } = useURLGeneratorContext()

  const deckFeedLink = determineDeckFeedLink(params)
  const slug = determineCurrentSlug(params)
  const currentPageIsDeckFeed = pathname === deckFeedLink

  return {
    label: __`deck-feed`,
    icon: deckIcon,
    value: 'feed',
    type: currentPageIsDeckFeed ? 'button' : 'link',
    onClick: onCloseDrawers,
    href: `${deckFeedLink}#${slug}`,
    isActive: currentPageIsDeckFeed && !drawerIsOpen
  }
}

function useMenuItemArticlesOverview({ drawerIsOpen }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const { pathname } = useLocation()

  const articlesOverviewLink = routeMap.app.articles({ language })
  const currentPageIsArticlesOverview = pathname === articlesOverviewLink

  return {
    label: __`overview`,
    icon: articlesOverviewIcon,
    value: 'articles-overview',
    type: 'link',
    href: articlesOverviewLink,
    isActive: currentPageIsArticlesOverview && !drawerIsOpen
  }
}

function useMenuItemSearch({ searchDrawer }) {
  const { __ } = useTranslate()

  return {
    label: __`search`,
    icon: searchIcon,
    value: 'search',
    type: 'button',
    onClick: searchDrawer.handleOpen,
    isActive: searchDrawer.isOpen
  }
}
