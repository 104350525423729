/** @type {React.Context<{region: string, issue: string, bank: { woodwing?: string, regio?: string }}>} */
const searchContext = React.createContext(null)

export function useSearchContext() {
  return React.useContext(searchContext)
}

export function SearchContextProvider({ children, doc = undefined, issue = undefined, feedData = undefined }) {
  const regio = feedData?.bank?.woodwing || feedData?.bank?.regio

  const data = {
    region: regio ? `Rabo en Co ${regio}` : doc?.publicationLevelName,
    issue,
    bank: feedData?.bank
  }

  return <searchContext.Provider value={data} {...{ children }} />
}
