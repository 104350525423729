import { useTranslate } from '/machinery/I18n'

import { Icon } from '/features/buildingBlocks/Icon'
import { InputText } from '/features/buildingBlocks/Input'

import styles from './SearchForm.css'

import iconSearch from '/images/icons/search.raw.svg'
import iconMark from '/images/icons/mark.raw.svg'

export function SearchForm({ value, onChange, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  const inputRef = React.useRef(null)

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <Icon icon={iconSearch} layoutClassName={styles.iconLayout} />

      <div ref={inputRef} className={styles.inputContainer}>
        <InputText
          hasIcon
          placeholder={`${__`i-am-searching-for`}...`}
          maxLength={64}
          onChange={handleChange}
          dataX='search'
          layoutClassName={styles.inputLayout}
          {...{ value }}
        />
      </div>

      {Boolean(value.length) && (
        <button onClick={handleDeletePrompt} className={styles.deletePromptButton}>
          <Icon icon={iconMark} layoutClassName={styles.iconLayout} />
        </button>
      )}
    </div>
  )

  function handleChange(e) {
    onChange(e.currentTarget.value)
  }

  function handleDeletePrompt() {
    const input = inputRef.current?.childNodes[0]

    onChange('')
    input.focus()
  }
}
