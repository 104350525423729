import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Button.css'

export function ButtonIcon({ dataX, label, onClick, icon, type = 'button', layoutClassName = undefined, disabled = undefined }) {
  return (
    <ButtonBase className={cx(styles.componentIcon, layoutClassName)} {...{ type, dataX, label, onClick, disabled }}>
      <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
    </ButtonBase>
  )
}

export function ButtonIconWithLabel({ dataX, label, onClick, icon, type = 'button', layoutClassName = undefined }) {
  return (
    <ButtonBase className={cx(styles.componentIconWithLabel, layoutClassName)} {...{ type, dataX, label, onClick }}>
      <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
      {label}
    </ButtonBase>
  )
}

export function ButtonIconWithLabelReverse({ dataX, label, onClick, icon, type = 'button', layoutClassName = undefined }) {
  return (
    <ButtonBase className={cx(styles.componentIconWithLabelReverse, layoutClassName)} {...{ type, dataX, label, onClick }}>
      {label}
      <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
    </ButtonBase>
  )
}

export function Button({ dataX, label, onClick, layoutClassName = undefined }) {
  return (
    <ButtonBase className={layoutClassName} {...{ dataX, label, onClick }}>
      {label}
    </ButtonBase>
  )
}

export function ButtonSecondary({ dataX, label, onClick, layoutClassName = undefined }) {
  return (
    <ButtonBase className={cx(styles.componentSecondary, layoutClassName)} {...{ dataX, label, onClick }}>
      {label}
    </ButtonBase>
  )
}

export function ButtonTertiary({ dataX, label, onClick, layoutClassName = undefined, disabled = undefined }) {
  return (
    <ButtonBase className={cx(styles.componentTertiary, layoutClassName)} {...{ dataX, label, disabled, onClick }}>
      {label}
    </ButtonBase>
  )
}

function ButtonBase({
  children,
  dataX,
  onClick,
  label,
  type = 'button',
  disabled = undefined,
  className = undefined
}) {
  return (
    <button
      data-x={dataX}
      aria-label={label}
      className={cx(styles.componentBase, className)}
      {...{ type, onClick, disabled }}
    >
      {children}
    </button>
  )
}

export function ButtonLinkPrimary({ href, dataX, label, icon = undefined, layoutClassName = undefined }) {
  return <ButtonLinkBase className={cx(styles.componentLinkPrimary, layoutClassName)} {... { href, dataX, label, icon }} />
}

export function ButtonLinkSecondary({ href, dataX, label, icon = undefined, layoutClassName = undefined }) {
  return <ButtonLinkBase className={cx(styles.componentLinkSecondary, layoutClassName)} {... { href, dataX, label, icon }} />
}

function ButtonLinkBase({ href, dataX, label, icon, className }) {
  const protocols = ['http', 'https', 'tel', 'mailto']
  const hasProtocol = protocols.some(x => href.startsWith(x))
  const target = hasProtocol ? '_blank' : '_self'
  const rel = hasProtocol ? 'noopener noreferrer' : null

  return (
    <a
      data-x={dataX}
      aria-label={label}
      className={cx(styles.componentLinkBase, className)}
      {...{ href, rel, target }}
    >
      {icon && <Icon layoutClassName={styles.iconLayout} {...{ icon }} /> }
      <span className={styles.label}>{label}</span>
    </a>
  )
}
