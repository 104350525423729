import { routeMap } from '/routeMap'

/** @type {React.Context<ContextData>} */
const urlGeneratorContext = React.createContext(undefined)

export function useURLGeneratorContext() {
  return React.useContext(urlGeneratorContext)
}

export function URLGeneratorProvider({ determineItemSlug, determineDeckFeedLink, determineCurrentSlug, determineItemUrl, children }) {
  const data = {
    determineItemSlug,
    determineDeckFeedLink,
    determineCurrentSlug,
    determineItemUrl
  }

  return <urlGeneratorContext.Provider value={data} {...{ children }} />
}

export function issueURLGeneratorProviderData() {
  return {
    determineItemUrl(item, { language, issue, bankcode }) {
      return routeMap.app.issue.region.article({ language, issue, bankcode, rubricSlug: determineItemSlug(item) })
    },
    determineItemSlug,
    determineDeckFeedLink: ({ language, issue, bankcode }) => routeMap.app.issue.region({ language, issue, bankcode }),
    determineCurrentSlug: params => params.rubricSlug
  }

  function determineItemSlug(item) {
    return item.metadata.rubric.slug
  }
}

export function specialURLGeneratorProviderData() {
  return {
    determineItemUrl(item, { language, specialSlug }) {
      return routeMap.app.specials.special.article({ language, specialSlug, idOrIds: item.id, specialArticleSlug: item.slug })
    },
    determineItemSlug,
    determineDeckFeedLink: ({ language, specialSlug }) => routeMap.app.specials.special({ language, specialSlug }),
    determineCurrentSlug: params => params.specialArticleSlug
  }

  function determineItemSlug(item) {
    return item.slug
  }
}

/**
 * @typedef {{
* determineItemSlug: (item: any) => string,
* determineDeckFeedLink: (params: object) => string,
* determineCurrentSlug: (params: object) => string,
* determineItemUrl: (item: object, params: object) => string
* }} ContextData
*/
