import { useQuery } from '@tanstack/react-query'
import { useQueryString } from '@kaliber/sanity-routing/queryString'

import { useLanguage } from '/machinery/I18n'
import { useSearchContext } from '/machinery/SearchContext'
import { useDebouncedValue } from '/machinery/useDebounced'
import { ensureArray, ensureBoolean } from '/machinery/ensure'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

import { getEsEditionRubrics, getEsEditionTags, getEsSearchResult, getEsSearchResultWithinAndNotWithinRegion } from '/features/search/requests'

export function useSearchArticles({
  enabled = defaultEnabledFn,
  initialData = regionSpecificInitialData(),
} = {}) {
  const language = useLanguage()
  const [searchValue, setSearchValue] = React.useState('')

  const [{ tags, searchAllIssues }] = useSearchQueryParams()
  const { bank, region, issue: rawIssue } = useSearchContext() || {}
  const debouncedSearch = useDebouncedValue(searchValue)

  const issue = searchAllIssues ? null : rawIssue

  const placeholderDataRef = React.useRef(initialData)

  const { data, isError, isFetching } = useQuery({
    queryKey: ['searchArticles', debouncedSearch, language, bank, region, issue, tags].filter(Boolean),
    queryFn: async () => {
      const data = await getSearchResult({ searchString: debouncedSearch, bank, region, issue, language, tags })
      placeholderDataRef.current = data
      return data
    },
    placeholderData: placeholderDataRef.current,
    refetchOnWindowFocus: false,
    initialData,
    enabled: enabled(debouncedSearch, tags)
  })

  const isFiltering = debouncedSearch || tags.length

  return {
    search: {
      value: searchValue,
      setValue: setSearchValue
    },
    results: data,
    status: {
      isFiltering,
      isError,
      isFetching
    }
  }

  async function getSearchResult({ searchString, bank, region, issue, tags, language }) {
    if (!searchString && tags.length === 0) return { withinRegion: {}, notWithinRegion: {} }

    const { withinRegion, notWithinRegion } = await getEsSearchResultWithinAndNotWithinRegion({ searchString, bank, region, issue, tags, language, size: 999 })

    filterChangedEvent({ tags, searchString, total: withinRegion.total })

    return { withinRegion, notWithinRegion }
  }
}

export function useSearchArticlesCanonical({
  size,
  from = 0,
  initialData = { articles: [], total: 0 }, enabled = defaultEnabledFn }
) {
  const language = useLanguage()
  const [searchValue, setSearchValue] = React.useState('')
  const [{ tags, searchAllIssues, rubrics }] = useSearchQueryParams()
  const debouncedSearch = useDebouncedValue(searchValue)
  const { issue: rawIssue } = useSearchContext()

  const issue = searchAllIssues ? null : rawIssue

  const placeholderDataRef = React.useRef(initialData)

  const { data, isError, isFetching } = useQuery({
    queryKey: ['searchArticlesCanonical', debouncedSearch, language, tags, issue, rubrics, from].filter(Boolean),
    queryFn: async () => {
      const data = await getSearchResult({ searchString: debouncedSearch, language, issue, tags, from, rubrics })
      placeholderDataRef.current = data
      return data
    },
    initialData,
    placeholderData: placeholderDataRef.current,
    refetchOnWindowFocus: false,
    enabled: enabled(debouncedSearch, tags)
  })

  const isFiltering = debouncedSearch || tags.length

  return {
    search: {
      value: searchValue,
      setValue: setSearchValue
    },
    results: data,
    status: {
      isFiltering,
      isError,
      isFetching
    }
  }

  async function getSearchResult({ searchString, tags, rubrics, issue, language, from }) {
    const { articles, total } = await getEsSearchResult({ searchString, tags, issue, rubrics, language, size, from })

    filterChangedEvent({ tags, searchString, total })

    return { articles, total }
  }
}

export function useTags() {
  const language = useLanguage()
  const { bank, region, issue: rawIssue } = useSearchContext() || {}
  const [{ searchAllIssues, tags: selectedTags }, setQueryString] = useSearchQueryParams()

  const issue = searchAllIssues ? null : rawIssue
  const { data } = useQuery({
    queryKey: ['tags', language, bank, region, issue],
    queryFn: () => getEsEditionTags({ language, bank, issue, region }),
    initialData: {},
    refetchOnWindowFocus: false
  })

  const { tags = [] } = data

  return {
    tags: tags.map((x, buttonId) => ({ ...x, buttonId, isActive: selectedTags.includes(x.value) })),
    handleTagClick
  }

  function handleTagClick(value) {
    const isAlreadyIncluded = selectedTags.includes(value)
    const newTags = isAlreadyIncluded ? selectedTags.filter(x => x !== value) : [...selectedTags, value]
    setQueryString(x => ({ ...x, tags: newTags, page: undefined }))
  }
}

export function useRubrics() {
  const language = useLanguage()
  const { bank, region, issue: rawIssue } = useSearchContext() || {}
  const [{ searchAllIssues, rubrics: selectedRubrics }, setQueryString] = useSearchQueryParams()
  const issue = searchAllIssues ? null : rawIssue

  const { data } = useQuery({
    queryKey: ['rubric', language, bank, region, issue],
    queryFn: () => getEsEditionRubrics({ language, bank, issue, region }),
    initialData: {},
    refetchOnWindowFocus: true
  })

  const { rubrics = [] } = data

  return {
    rubrics: rubrics.map((x, buttonId) => ({ ...x, buttonId, isActive: selectedRubrics.includes(x.value) })),
    handleRubricClick
  }

  function handleRubricClick(value) {
    const isAlreadyIncluded = selectedRubrics.includes(value)
    const newRubrics = isAlreadyIncluded ? selectedRubrics.filter(x => x !== value) : [...selectedRubrics, value]
    setQueryString(x => ({ ...x, rubrics: newRubrics, page: undefined }))
  }
}

/**
 * @returns {[
 * {tags: string[], searchAllIssues: boolean, rubrics: string[]},
 * (any) => void, ]}
 */
export function useSearchQueryParams() {
  const [{ tags = [], searchAllIssues = false, rubrics = [] }, setQueryString] = useQueryString()
  return [
    {
      tags: ensureArray(tags),
      rubrics: ensureArray(rubrics),
      searchAllIssues: ensureBoolean(searchAllIssues),
    },
    setQueryString
  ]
}

function filterChangedEvent({ tags, searchString, total }) {
  pushToDataLayer({
    event: 'filter_changed',
    metadata: {
      filter: {
        ...(tags?.[0] && { tag: tags[0] }),
        ...(searchString && { searchterm: searchString }),
        maxresults: total
      }
    }
  })
}

function defaultEnabledFn(searchString, tags) {
  return Boolean(searchString) || tags.length > 0
}

function regionSpecificInitialData() {
  return {
    withinRegion: { articles: [], total: 0 },
    notWithinRegion: { articles: [], total: 0 }
  }
}
