import { useSpring, animated } from '@react-spring/web'

import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Logo.css'

import logo from '/images/branding/rabo-en-co-logo.svg'
import logoSpecials from '/images/branding/rabo-en-co-specials-logo.svg'

const config = { mass: 1, tension: 280, friction: 60 }

export function Logo({
  isBig = false,
  hasGradient = false,
  subtitle = undefined,
  layoutClassName = undefined,
}) {
  return (
    <LogoBase
      src={logo}
      width={175}
      className={layoutClassName}
      {...{ isBig, subtitle, hasGradient }}
    />
  )
}

export function LogoSpecials({
  isBig = false,
  hasGradient = false,
  subtitle = undefined,
  layoutClassName = undefined,
}) {
  return (
    <LogoBase
      src={logoSpecials}
      width={150}
      className={layoutClassName}
      {...{ isBig, subtitle, hasGradient }}
    />
  )
}

function LogoBase({
  src,
  width,
  isBig = false,
  hasGradient = false,
  subtitle = undefined,
  className = undefined
}) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg)
  const logoWidthAsBig = isViewportLg ? width * 1.15 : width

  const style = useSpring({ width: isBig ? logoWidthAsBig : 100, config })
  const styleText = useSpring({ scale: isBig ? 1 : 0.5, config })

  return (
    <div className={cx(styles.componentBase, className)}>
      <animated.img width='200px' alt="logo" className={styles.logo} {...{ src, style }} />
      {subtitle && <animated.h1 style={styleText} className={styles.text}>{subtitle}</animated.h1>}
      {hasGradient && <div className={styles.backgroundGradient} />}
    </div>
  )
}
